.retailer-list {
    display: none;
    margin: 0 auto;

    &.active {
        display: flex;
    }

    .wrapper-button {
        margin-top: 40px;
    }

    .wrapper-radio-box {
        position: relative;
        height: 100%;

        input + label:before {
            bottom: 21px;
        }
    }
}


.retailer-list.loading {
    min-height: $gap-smallest-part*5;
    background-image: url('#{$staticAssets}/img/gui/idle.gif');
    background-position: center center;
    background-repeat: no-repeat;
}

.retailer-item {

    .container-text {
        padding: 35px 20px;
    }

    .button-to-retailer {
        margin-top: 50px;
    }

    .button-circle.button-extra-small.btn-icon-info {
        display: inline-flex;
        right: auto;
        top: auto;
        margin-left: 5px;

        .circle-wrapper {
            display: inline-flex;
        }
    }
}
