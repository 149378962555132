// font-vars

$font-flexo-thin: 'FlexoW03-Thin';
$font-flexo-thin-italic: 'FlexoW03-ThinItalic';
$font-flexo-regular: 'FlexoW03-Regular';
// todo -- PLAYGROUND
$font-flexo-demi: 'FlexoW03-Demi';
$font-flexo-bold: 'FlexoW03-Bold';
$font-icons: 'riesemueller';

$font-src-path: '#{$staticAssets}/font';

@font-face{
    font-family:"FlexoW03-Thin";
    src:url("#{$font-src-path}/flexo/FlexoW03-Thin.eot?#iefix");
    src:url("#{$font-src-path}/flexo/FlexoW03-Thin.eot?#iefix") format("eot"),url("#{$font-src-path}/flexo/FlexoW03-Thin.woff2") format("woff2"),url("#{$font-src-path}/flexo/FlexoW03-Thin.woff") format("woff"),url("#{$font-src-path}/flexo/FlexoW03-Thin.ttf") format("truetype");
}
@font-face{
    font-family:"FlexoW03-ThinItalic";
    src:url("#{$font-src-path}/flexo/FlexoW03-ThinItalic.eot?#iefix");
    src:url("#{$font-src-path}/flexo/FlexoW03-ThinItalic.eot?#iefix") format("eot"),url("#{$font-src-path}/flexo/FlexoW03-ThinItalic.woff2") format("woff2"),url("#{$font-src-path}/flexo/FlexoW03-ThinItalic.woff") format("woff"),url("#{$font-src-path}/flexo/FlexoW03-ThinItalic.ttf") format("truetype");
}
@font-face{
    font-family:"FlexoW03-Regular";
    src:url("#{$font-src-path}/flexo/FlexoW03-Regular.eot?#iefix");
    src:url("#{$font-src-path}/flexo/FlexoW03-Regular.eot?#iefix") format("eot"),url("#{$font-src-path}/flexo/FlexoW03-Regular.woff2") format("woff2"),url("#{$font-src-path}/flexo/FlexoW03-Regular.woff") format("woff"),url("#{$font-src-path}/flexo/FlexoW03-Regular.ttf") format("truetype");
}
@font-face{
    font-family:"FlexoW03-Demi";
    src:url("#{$font-src-path}/flexo/FlexoW03-Demi.eot?#iefix");
    src:url("#{$font-src-path}/flexo/FlexoW03-Demi.eot?#iefix") format("eot"),url("#{$font-src-path}/flexo/FlexoW03-Demi.woff2") format("woff2"),url("#{$font-src-path}/flexo/FlexoW03-Demi.woff") format("woff"),url("#{$font-src-path}/flexo/FlexoW03-Demi.ttf") format("truetype");
}
@font-face {
    font-family: "FlexoW03-Bold";
    src: url("#{$font-src-path}/flexo/FlexoW03-Bold.eot?#iefix");
    src: url("#{$font-src-path}/flexo/FlexoW03-Bold.eot?#iefix") format("eot"), url("#{$font-src-path}/flexo/FlexoW03-Bold.woff2") format("woff2"), url("#{$font-src-path}/flexo/FlexoW03-Bold.woff") format("woff"), url("#{$font-src-path}/flexo/FlexoW03-Bold.ttf") format("truetype");
}


// FONT RM
@font-face {
    font-family: "riesemueller";
    src: url("#{$font-src-path}/icons/riesemueller.eot");
    src: url("#{$font-src-path}/icons/riesemueller.eot?#iefix") format("embedded-opentype"),
        url("#{$font-src-path}/icons/riesemueller.woff") format("woff"),
        url("#{$font-src-path}/icons/riesemueller.ttf") format("truetype"),
        url("#{$font-src-path}/icons/riesemueller.svg#riesemueller") format("svg");
    font-weight: normal;
    font-style: normal;
}
