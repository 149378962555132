.m11-lifestyle-slider {

    .container-quote {
        margin-bottom: 32px;

        @media #{$mq-medium-up} {
            margin-bottom: 42px;
        }
    }
}


.m12-bike-chooser {

    $xl: $screen-max-width + ($padding-container-center-large * 2);

    @media #{$mq-medium-up} {
        .slider-rebrush.slider-snap-scroll .owl-nav {
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    @media #{$mq-large-up} {
        .slider-rebrush.slider-snap-scroll .owl-nav {
            left: calc(50% - 100% / 10 + 7.5px);
        }
    }

    @media only screen and (min-width: $xl) {
        .xl-12 {
            width: calc((100% - #{$grid-gutter--mq-large})/ 1);
        }
        .xl-left-0 {
            margin-left: $grid-gutter--mq-large;
        }
        .slider-rebrush.slider-snap-scroll .owl-nav {
            left: 50%;
        }
    }

    @media #{$mq-small-only} {
        .slider-rebrush.slider-snap-scroll .owl-item .slider-rebrush__slide {
            opacity: 1;
        }
    }
}

// bei grauem Modul-Hintergrund müssen pager-Punkte weiß werden
.background-grey .slider-rebrush {
    .dot:before {
        background-color: $color-white;
    }
}

.slider-rebrush {
    position: relative;

    &.full-height {
        .owl-stage {
            display: flex;
        }

        .owl-item {
            flex: 1 0 auto;
            display: flex;

            .slider-rebrush__slide > * {
                height: 100%;
            }
        }
    }

    .slider-rebrush__container {
        position: relative;
    }

    figure.slide.background {
        background-size: contain;

        @include relative-height(3/2);

        &.fitting {
            background-color: $color-ultra-light-grey-hex;
        }
    }

    .owl-stage {

    }

    .owl-item {

        .slider-rebrush__slide {
            opacity: 0.4;
            transition: opacity 0.3s ease-in-out;

            &:hover {
                border-bottom: none;
            }
        }

        &.active {
            .slider-rebrush__slide {
                opacity: 1.0;
            }
        }

        &.hover {
            .slider-rebrush__slide {
                opacity: 1.0;
            }
        }

        // Hack when fading so you cant trigger the wrong lightbox
        &.owl-animated-out {
            pointer-events: none;
        }
    }

    .slider-rebrush__slide {
        width: 100%;
        display: block;
        border: none;

        img {
            margin: 0 auto;
            max-width: 1600px;
            pointer-events: none;
            user-drag: none;
            -webkit-user-drag: none;
            @include no-select();
        }

        & > figure.background {
            @include relative-height(3/2);
        }
    }

    .owl-captions {
        margin-top: 8px;

        @media #{$mq-medium-up} {
            margin-top: 0;
        }
    }

    .owl-captions {

        .owl-caption {
            transition: opacity .25s ease-in-out 0s;
            opacity: 0;
            top: 0;
            font-size: 13px;
            line-height: 1.62;

            &.active {
                opacity: 1;
                transition: opacity .25s ease-in-out .25s;
            }
        }

        .owl-caption-text {
            font-size: 13px;
            line-height: 1.62;
        }

        &.disabled {
            display: none;
        }
    }

    .owl-captions__inner {
        position: relative;
        width: 1000%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        // Variable nur für untenstehendes Mixin
        $total-items: 15;

        @mixin caption-classes() {
            @for $i from 2 through $total-items - 1 {
                &:nth-child(#{$i}) {
                    left: #{-10% * ($i - 1)};
                }
            }
        }

        .owl-caption {
            position: relative;
            width: 10%;
            @include caption-classes();
        }
    }


    .owl-stage {
        padding-bottom: 32px;

        @media #{$mq-medium-up} {
            padding-bottom: 42px;
        }
    }

    .owl-dots-custom--disabled .owl-stage {
        padding-bottom: 0;
    }


    .owl-footer {
        //margin-left: 10px;
        //margin-right: 10px;
        //margin-bottom: -5px;
        font-size: $p_fontsize;
    }

    .owl-captions,
    .owl-counter {
        line-height: 1;
    }

    .owl-footer > div {
        margin-top: 10px;
    }

    .owl-counter {
        flex: 0 1 auto;
        white-space: nowrap;
        color: $color-medium-grey;
        margin-right: 10px;

        &__active {
            font-family: $font-flexo-demi;
            color: $color-black;
        }


        //@media #{$mq-medium-up} {
            line-height: 1.5;
        //}

    }

    // OWL NAVIGATION

    .owl-nav {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


    .owl-nav {
        .owl-prev,
        .owl-next {
            opacity: 0;
            transition: opacity .3s ease-in-out;

            &:not(.disabled) {
                .touch & {
                    @media #{$mq-medium-up} {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }

        &:hover {
            .owl-prev,
            .owl-next {
                &:not(.disabled) {
                    .no-touch & {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }
    }




    .owl-prev, .owl-next {
        @extend .button-pager-extend;

        position: absolute;
        top: 50%;
        text-align: center;
        cursor: pointer;

        width: 70px;
        height: 70px;
        background-color: rgba(226, 225, 225, 0.5);
        border-radius: 50%;

        &.disabled {
            opacity: 0;
            pointer-events: none;
        }
    }

    .owl-prev {
        left: 20px;
        transform: translate(0, -50%);

        &:before {
            content: 'b';
            line-height: 70px;
        }

        &:hover:before {
            .no-touch & {
                transform: translate3d(-4px, 0, 0);
            }
        }
    }

    .owl-next {
        right: 20px;
        transform: translate(0, -50%);

        &:before {
            content: 'c';
            line-height: 70px;
        }

        &:hover:before {
            .no-touch & {
                transform: translate3d(4px, 0, 0);
            }
        }
    }

    .owl-dots {
        position: relative;
        z-index: 2;

        transition: opacity 0.5s ease-in-out;
        height: 2px;

        @media #{$mq-large-up} {
            margin-top: 20px;
        }

        margin: 0 -5px;
        margin-top: -2px!important;

        display: flex;
        justify-content: center;
        align-items: center;

        .owl-dot {

            position: relative;
            flex: 1 1 auto;
            height: 10px;
            cursor: pointer;
            margin: 0 5px;

            &:before {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                top: 4px;
                width: 100%;
                height: 2px;

                transition: background 0.5s ease-in-out;

                background: rgba(0,0,0,.12);
            }

            &.active {
                &:before {
                    background: $color-black;
                }
            }
        }

    }
}




.slider-rebrush.slider-snap-scroll {

    $offset_small_up: 20px - $grid-gutter--mq-small/2;
    $offset_medium_up: 55px - $grid-gutter--mq-medium/2;
    $offset_large_up: 55px - $grid-gutter--mq-large/2;

    margin-left: -$grid-gutter--mq-small/2;
    margin-right: -$grid-gutter--mq-small/2;

    @media #{$mq-medium-up} {
        margin-left: -$grid-gutter--mq-medium/2;
        margin-right: -$grid-gutter--mq-medium/2;
    }

    @media #{$mq-large-up} {
        margin-left: -$grid-gutter--mq-large/2;
        margin-right: -$grid-gutter--mq-large/2;
    }

    .owl-stage {
        padding-bottom: 22px;

        @media #{$mq-medium-up} {
            padding-bottom: 32px;
        }
    }

    .owl-dots-custom--disabled .owl-stage {
        padding-bottom: 0;
    }

    .owl-stage-outer {
        overflow: inherit;
    }



    .slider-rebrush__container {

        $gridMarginXS: 20px; //
        $gridMarginMD: 55px; //
        $gridMarginLG: 55px; //

        $itemPaddingXS: $grid-gutter--mq-small/2; // = 7.5
        $itemPaddingMD: $grid-gutter--mq-medium/2; // = 10
        $itemPaddingLG: $grid-gutter--mq-large/2; // = 12.5



        // Gap for both side
        // If you need the stage padding only on one side you need to hack this via css

        $gapXS: 15px;

        position: relative;
        left: -($gridMarginXS - $itemPaddingXS + $gapXS);
        //left: -($offset_small_up + $grid-gutter--mq-small);

        width: calc(100% + #{$offset_small_up * 2} + #{$gapXS});

        @media #{$mq-medium-up} {
            left: -($gridMarginMD - $itemPaddingMD);
            //left: -#{$offset_medium_up};

            width: calc(100% + #{$offset_medium_up * 2});
        }

        @media #{$mq-large-up} {
            left: -($gridMarginLG - $itemPaddingLG);
            //left: -#{$offset_large_up};

            width: calc(100% + #{$offset_large_up * 2});
        }

        //.m12-bike-chooser & {
        //    $gapXS: 25px;
        //
        //    position: relative;
        //    left: -($gridMarginXS - $itemPaddingXS + $gapXS);
        //    //left: -($offset_small_up + $grid-gutter--mq-small);
        //
        //    width: calc(100% + #{$offset_small_up * 2} + #{$gapXS});
        //
        //    @media #{$mq-medium-up} {
        //        left: -($gridMarginMD - $itemPaddingMD);
        //        //left: -#{$offset_medium_up};
        //
        //        width: calc(100% + #{$offset_medium_up * 2});
        //    }
        //
        //    @media #{$mq-large-up} {
        //        left: -($gridMarginLG - $itemPaddingLG);
        //        //left: -#{$offset_large_up};
        //
        //        width: calc(100% + #{$offset_large_up * 2});
        //    }
        //}
    }




    .slider-rebrush__slide {
        padding: 0 $grid-gutter--mq-small/2;

        @media #{$mq-medium-up} {
            padding: 0 $grid-gutter--mq-medium/2;
        }

        @media #{$mq-large-up} {
            padding: 0 $grid-gutter--mq-large/2;
        }
    }

    .owl-item {

        .slider-rebrush__slide {
            opacity: 0.4;
            transition: opacity 0.3s ease-in-out;
        }

        &.active.fullyVisible {
            .slider-rebrush__slide {
                opacity: 1.0;
            }
        }

        &.hover {
            .slider-rebrush__slide {
                opacity: 1.0;
            }
        }
    }

    .owl-captions {

        display: block;
        overflow: hidden;

        .owl-caption-separator {
            color: $color-medium-grey;
            padding: 0 10px;
        }
    }

    .owl-footer {
        margin-left: 0;
        margin-right: 0;

        @media #{$mq-medium-up} {
            //margin-left: 10px;
            //margin-right: 10px;
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
        }
    }

    .owl-captions__inner {

        .owl-caption {
            position: relative;
            flex: 0 0 10%;
            width: 10%;
            order: 100;

            @media #{$mq-medium-up} {

                &.active,
                &.active ~ * {
                    order: 1;
                }

                &.active {
                    flex: 0 1 10%;
                    width: 10%;
                    left: auto;

                    + .active {
                        margin-left: 20px;
                    }
                }

                &.active + *:not(.active) {
                    flex: 0 1 10%;
                    width: 10%;
                }
            }
        }
    }

    .owl-footer {
        > div {
            margin-top: 8px;
            margin-bottom: -5px;

            @media #{$mq-medium-up} {
                margin-top: 8px;
            }
        }

        .owl-counter + .owl-captions {
            margin-top: 7px;

            @media #{$mq-medium-up} {
                margin-top: 8px;
            }
        }
    }

    .owl-progress,
    .owl-dots-custom,
    .owl-footer {
       margin-left: $grid-gutter--mq-small/2;

        @media #{$mq-medium-up} {
            margin-left: $grid-gutter--mq-medium/2;
        }

        @media #{$mq-large-up} {
            margin-left: $grid-gutter--mq-large/2;
        }
    }

    // OWL DOTS

    //.owl-dots-custom,


    .owl-progress,
    .owl-dots-custom {
        position: relative;
        //width: 100%;
        z-index: 2;
        //margin-top: 15px;
        //margin-bottom: 0;

        transition: opacity 0.5s ease-in-out;

        width: calc(100% - 15px + #{$offset_small_up + $grid-gutter--mq-small/2});

        @media #{$mq-medium-up} {
            width: calc(100% - 20px + #{$offset_medium_up + $grid-gutter--mq-medium/2});

            //.m-10 & {
            //    width: calc(100% / 10 * 2 + 100% + #{$offset_medium_up} - #{$grid-gutter--mq-medium/2} );
            //}
        }

        @media #{$mq-large-up} {
            width: calc(100% - 20px + #{$offset_large_up + $grid-gutter--mq-large/2});

            //.l-8 & {
            //    width: calc(100% / 8 * 4 + 100% + #{$offset_large_up} - #{$grid-gutter--mq-large/2} );
            //}
            //
            //.l-10 & {
            //    width: calc(100% / 10 * 2 + 100% + #{$offset_large_up} - #{$grid-gutter--mq-large/2} );
            //}
        }




        //margin: 10px;

        background: rgba(0,0,0,0.12);
        height: 2px;

        @media #{$mq-large-up} {
            margin-top: 20px;
        }

        margin-bottom: 0;
        margin-right: 0;
        margin-top: -2px!important;

        //pointer-events: none;
    }

    .owl-progress {
        pointer-events: none;

        //background: green;
        //margin-top: -8px!important;

        .owl-progress__dragger {
            pointer-events: all;

            $touchHeight: 20px;
            $height: 2px;

            position: relative;
            position: absolute;
            height: $touchHeight;
            cursor: pointer;
            display: block;
            top: -($touchHeight/2 - $height/2);

            transition: all 0.25s ease 0s;

            &:before {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                top: $touchHeight/2 - $height/2;
                width: 100%;
                height: $height;
                background: $color-black;
            }
        }
    }

    .owl-dots-custom {

        display: flex;
        justify-content: center;
        align-items: center;

        .owl-dot {

            $touchHeight: 20px;
            $height: 2px;

            position: relative;
            flex: 1 1 auto;
            height: $touchHeight;
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                display: block;
                left: 0;
                top: $touchHeight/2 - $height/2;
                width: 100%;
                height: $height
            }
        }

    }

    .owl-dots-custom.disabled-manually,
    .owl-dots-custom.disabled {
        display: none;

        ~ .owl-progress,
        ~ .owl-footer .owl-counter {
            display: none;
        }
    }

    .owl-nav {
        //@media #{$mq-large-only} {
        //    transform: none;
        //    left: 0;
        //}
        //@media #{$mq-large-down} {
        //    transform: none;
        //    left: 0;
        //}
    }

    .owl-prev {
        transform: translate(0,-50%);
        left: 20px + $grid-gutter--mq-small;

        @media #{$mq-medium-up} {
            left: 20px;
        }
    }

    .owl-next {
        transform: translate(0,-50%);
        right: 20px - $grid-gutter--mq-small;

        @media #{$mq-medium-up} {
            right: 20px;
        }
    }

    .owl-nav {
        //height: 100%; // ??? working everywhere? is not working. dont do it ever again... no click possible :D
        height: 0;

        .owl-prev,
        .owl-next {
            .no-touch & {
                opacity: 0;
                pointer-events: none;
                transition: opacity .3s ease-in-out;
            }

            &.hover:not(.disabled) {
                .no-touch & {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    .owl-item {
        opacity: 0;

        &.animationComplete {
            opacity: 1;
        }
    }

    .init-animation-disabled .owl-item {
        opacity: 1;
    }
}







.slider-rebrush {
    //.slider-rebrush__slide[data-type="lightbox"]:hover {
    //
    //    .no-touch & {
    //        cursor: url('#{$staticAssets}/img/gui/rm_zoom_pointer.svg') 35 35, pointer;
    //    }
    //}


    &.slider-default {

        .owl-nav {
            pointer-events: none;

            &:before,
            &:after {
                position: absolute;
                content: '';
                top: 0;
                height: 100%;
                pointer-events: all;
                z-index: -1;
                //background: rgba(255, 0, 255, 0.2);

                min-width: 110px;

                @media #{$mq-medium-up} {
                    width: calc((100% / 12 * 1) + #{$padding-container-center-large} - 7.5px);
                }

                @media #{$mq-large-up} {
                    width: calc((100% / 12 * 2) + #{$padding-container-center-large} - 15px);
                }

                @media #{$mq-max-width-up-with-margin} {
                    width: calc(50% - #{$screen-max-width/2} + (#{$screen-max-width} / 12 * 2));
                }
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }
        }
    }
}

//.lightboxWrapper {
//
//    @media #{$mq-small-only} {
//        .module {
//            padding: 0!important;
//        }
//    }
//
//    figure.background {
//
//        width: calc((100vh - 60px) * 3 / 2 );
//        height: calc(100vh - 60px);
//
//        @media #{$mq-medium-up} {
//            width: auto;
//            height: auto;
//
//            max-width: calc(100vw - 120px);
//            max-height: calc(100vh - 120px);
//
//            margin: auto;
//        }
//
//        &::before {
//            content: '';
//            display: block;
//            padding-top: 66.66666667%;
//        }
//    }
//}




// Bike Colors

.slider-rebrush {

    display: flex;
    flex-direction: column;

    @media #{$mq-large-up} {
        display: block;
    }

    &__bike-colors {
        margin-top: 20px;
    }
}










